import {
    ref,
    reactive
} from 'vue';
import axios from '@/axios';
import store from '@/store';

export default function useApplication() {
    const errors = ref([]); //ref: to make it reactive
    const msgs = ref([]); //ref: to make it reactive
    const applications = ref([]); //ref: to make it reactive
    const application = ref([]); //ref: to make it reactive
    const userrequest = ref([]);
    const lists = reactive({
        'countries': [],
        'systems': [],
        'levels': [],
        'martial_states': [],
        'legal_custody_persons': [],
        'religions': [],
        'nationalities': [],
        'languages': [],
        'transportation_types': [],
        'contact_persons': [],
        'statuses': [],
        'payment_statuses': [],
    });

    const getApplications = async (data = {}) => {
        try {
            let response = await axios.post('/api/applications/get', data);
            applications.value = response.data.data;
        } catch (e) {
            await store.dispatch("handleException", e);
        }

    }

    const getApplication = async (id) => {
        try {
            let response = await axios.get('/api/applications/' + id + '/edit');
            application.value = response.data.data;
        } catch (e) {
            await store.dispatch("handleException", e);
        }

    }

    const storeApplication = async (data) => {
        errors.value = ''; //
        try {
            let response = await axios.post('/api/applications/', data);
            return response.data.data;
        } catch (e) {
            errors.value = await store.dispatch("handleException", e);
            return 'fail';
        }

    }

    const UpdateApplication = async (data, id) => {
        errors.value = ''; //
        try {
            data.append("_method", 'PATCH');
            let response = await axios.post('/api/applications/' + id, data);
            return response.data.data;
        } catch (e) {
            errors.value = await store.dispatch("handleException", e);
            return 'fail';
        }

    }

    const UpdateApplicationStatuses = async (data, id) => {
        errors.value = ''; //
        msgs.value = ''; //
        try {
            let response = await axios.post('/api/applications/statuses/' + id + '/update', data);
            msgs.value = response.data.message
        } catch (e) {
            errors.value = await store.dispatch("handleException", e);
        }
    }

    const getListsForApplication = async () => {
        try {
            let response = await axios.get('/api/get_lists_application');
            lists.countries = response.data.data.countries
            lists.systems = response.data.data.systems
            lists.levels = response.data.data.levels
            lists.martial_states = response.data.data.martial_states
            lists.legal_custody_persons = response.data.data.legal_custody_persons
            lists.religions = response.data.data.religions
            lists.nationalities = response.data.data.nationalities;
            lists.languages = response.data.data.languages
            lists.transportation_types = response.data.data.transportation_types
            lists.contact_persons = response.data.data.contact_persons
        } catch (e) {
            await store.dispatch("handleException", e);

        }
    }

    const getApplicationStatuses = async () => {
        try {
            let response = await axios.get('/api/get_application_statuses');
            lists.statuses = response.data.data.statuses
            lists.payment_statuses = response.data.data.payment_statuses
        } catch (e) {
            await store.dispatch("handleException", e);

        }
    }

    const getApplicationFees = async (id) => {
        try {
            let response = await axios.get('/api/applications/fees/' + id);
            userrequest.value = response.data.data
            return response.data.data
        } catch (e) {
            await store.dispatch("handleException", e);
        }
    }

    const deleteApplication = async (id) => {
        try {
            await axios.delete('/api/applications/' + id);
        } catch (e) {
            await store.dispatch("handleException", e);

        }
    }

    const downloadApplicationFile = async (id, filetype) => {
        try {

            axios({
                url: '/api/applications/file/' + id + '/' + filetype,
                method: 'GET',
                responseType: 'blob',
            }).then((response) => {
               
                let fileExtension = response.data.type.split('/').pop();

                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', id + '-' + filetype + '.'+fileExtension);
                document.body.appendChild(fileLink);
              
                fileLink.click();
            });
        } catch (e) {
            await store.dispatch("handleException", e);

        }
    }

    const checkBankMisrPaymentStatus = async (merchant_ref_num) => {
        try {
            let response = await axios.post('/api/user_requests/bank_misr_trans_credit_card_check', { 'merchant_ref_num': merchant_ref_num });
            return response.data;
        } catch (e) {
            await store.dispatch("handleException", e);
            return 'fail';
        }
    }



    return {
        applications,
        application,
        getApplications,
        getApplication,
        getApplicationFees,
        getListsForApplication,
        getApplicationStatuses,
        storeApplication,
        UpdateApplication,
        deleteApplication,
        UpdateApplicationStatuses,
        downloadApplicationFile,
        checkBankMisrPaymentStatus,
        userrequest,
        lists,
        msgs,
        errors,
    }
}