<template>
    <div>
        <div class="row page-titles mx-0">
            <div class="col-md-6 p-md-0">
                <div class="welcome-text">
                    <h4>Applications</h4>
                </div>
            </div>
            <div class="col-md-6 p-md-0 justify-content-md-end mt-2 mt-md-0 d-flex">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="/">Home</a></li>
                    <li class="breadcrumb-item">
                        <router-link :to="{name: 'Dashboard-Home'}">Dashboard</router-link>
                    </li>
                    <li class="breadcrumb-item">
                        <router-link :to="{name: 'Applications'}">Applications</router-link>
                    </li>
                    <li class="breadcrumb-item active">Statuses</li>
                </ol>
            </div>
        </div>
        <div class="row tab-content">
            <div id="list-view" class="tab-pane fade active show col-lg-12">
                <div class="card top-red-border">
                    <div class="card-header">
                        <h4 class="card-title">Statuses Management</h4>
                    </div>
                    <div class="card-body">
                        <!-- FeedBack -->
                        <feedback-messages :msgs="msgs" :errors="errors" />
                        <!-- /FeedBack -->
                        <!-- /FeedBack -->
                        <div class="basic-form">
                            <form @submit.prevent="submitFormData()">
                                <!-- Payment Status -->
                                <div v-can="'payment_status_applications'" class="student-information">
                                    <div class="form-group row">
                                        <label class="col-md-12 col-form-label" style="color:#781b20"><i
                                                class="custom-left-border"></i> Payment Status</label>
                                    </div>
                                    <div class="custom-left-tall-border">
                                        <div class="form-group row">
                                            <label class="col-md-2 col-form-label">Status <span style="color:red">*</span></label>
                                            <div class="col-md-20">
                                                <select class="form-control" required name="payment_status_raw" v-model="application.payment_status_raw">
                                                    <option value="" disabled selected>Nothing Selected</option>
                                                    <option v-for="(status, index) in lists.payment_statuses" :key="index"
                                                        :value="index">{{ status }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-md-2 col-form-label">Comment</label>
                                            <div class="col-md-20">
                                                <textarea class="form-control" name="payment_comment" v-model="application.payment_comment"
                                                    placeholder="In case of exempted, please state the reasons"></textarea>
                                            </div>
                                        </div> 
                                        <div class="form-group row">
                                            <label class="col-md-2 col-form-label">Attachment <span style="color:red">*</span>
                                             <small v-if="application.paymnet_proof_file_path"><br><a
                                                        style="color: blue;font-style: italic;font-weight: 700;cursor: pointer;" 
                                                        @click.prevent="downloadFile(application.id, 'paymnet_proof_file')"
                                                        target="_blank">Download file</a></small>
                                            </label>
                                            <div class="col-md-20">
                                              <input type="file" name="paymnet_proof_file" @change="handleFiles" class="dropify" accept="image/*, .pdf, .doc, .docx">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Application Status -->
                                <div v-can="'status_applications'" class="student-information">
                                    <div class="form-group row">
                                        <label class="col-md-12 col-form-label" style="color:#781b20"><i
                                                class="custom-left-border"></i> Application Status</label>
                                    </div>
                                    <div class="custom-left-tall-border">
                                        <div class="form-group row">
                                            <label class="col-md-2 col-form-label">Status <span style="color:red">*</span></label>
                                            <div class="col-md-20">
                                                <select class="form-control" required name="status" v-model="application.status_raw">
                                                    <option value="" disabled selected>Nothing Selected</option>
                                                    <option v-for="(status, index) in lists.statuses" :key="index"
                                                        :value="index">{{ status }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group row" style=" padding-top: 2rem; margin-bottom: 0px; ">
                                    <div class="col-md-12">
                                        <button :disabled="disableBtn" name="submit" type="submit"
                                            class="btn btn-primary" value="Submit" onclick="this.blur();"> Submit
                                        </button>
                                    </div>
                                </div>

                            </form>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import FeedbackMessages from '@/components/FeedbackMessages.vue';
    import useApplication from "@/composables/applications";
    import {
        useRouter
    } from 'vue-router'
    import {
        onMounted,
        ref,
        inject,
        onBeforeMount
    } from 'vue';
    export default {
        props: {
            id: {
                required: true,
                type: Number
            }
        },
        components: {
            FeedbackMessages
        },
        setup(props) {

            var formDataObj = new FormData();
            const swal = inject('$swal');
            const disableBtn = ref(false);
            const hasAnyPermission = inject('hasAnyPermission');
            const router = useRouter();

            onBeforeMount(() => {
                //first: check the user Permission
                if (!hasAnyPermission('status_applications,payment_status_applications'))
                    router.push({
                        name: '401'
                    })
            });

            const {
                getApplication,
                getApplicationStatuses,
                UpdateApplicationStatuses,
                downloadApplicationFile,
                application,
                lists,
                msgs,
                errors,
            } = useApplication();


            onMounted(() => {
                getApplication(props.id)
                getApplicationStatuses()
            })

            const handleFiles = async (e) => {
                let file = e.target.files[0];
                let name = e.target.name;
                formDataObj.append(name, file);
            }

            const submitFormData = async () => {

                const applicationArray = Object.entries(application.value);

                applicationArray.forEach((row) => {
                    let name = row[0];
                    let value = row[1];
                    formDataObj.append(name, value);
                });

                swal.fire({
                    title: 'Please wait while process your request',
                    allowOutsideClick: false,
                    didOpen: () => {
                        swal.showLoading()
                    }
                });

                errors.value = [];
                disableBtn.value = true;

                await UpdateApplicationStatuses(
                    formDataObj, props.id
                ).then(() => {
                    swal.close();
                    window.scrollTo(0, 0);
                    disableBtn.value = false;
                });
            }

            const downloadFile = async (id, filetype) => {
                await downloadApplicationFile(id, filetype);
            }


            return {
                props,
                disableBtn,
                application,
                lists,
                msgs,
                errors,
                submitFormData,
                handleFiles,
                downloadFile
            }
        }
    }
</script>